var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"1"}},[_c('validation-provider',{attrs:{"name":("nº pedido " + _vm.to),"rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nº Pedido","title":"Número do pedido do","required":"","error-messages":errors,"disabled":_vm.disabled || _vm.temPedidoASerDistribuido,"readonly":_vm.disabled || _vm.temPedidoASerDistribuido || _vm.suspendido},model:{value:(_vm.replicacao.numero_pedido),callback:function ($$v) {_vm.$set(_vm.replicacao, "numero_pedido", $$v)},expression:"replicacao.numero_pedido"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":_vm.to,"rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":_vm.toCapitalized,"required":"","items":_vm.toItems,"item-text":_vm.getLabelEmpresa,"item-value":"public_id","disabled":_vm.disabled,"error-messages":errors,"readonly":_vm.suspendido},model:{value:(_vm.replicacao.to),callback:function ($$v) {_vm.$set(_vm.replicacao, "to", $$v)},expression:"replicacao.to"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":_vm.isEdit ? 4 : 2}},[_c('validation-provider',{attrs:{"name":"terminal","rules":{ required: _vm.required }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Terminal","required":"","items":_vm.terminais,"item-text":_vm.getLabelEmpresa,"item-value":"public_id","disabled":_vm.disabled,"error-messages":errors,"readonly":_vm.suspendido},model:{value:(_vm.replicacao.terminal),callback:function ($$v) {_vm.$set(_vm.replicacao, "terminal", $$v)},expression:"replicacao.terminal"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"quantidade (Ton)","rules":{
          required: _vm.required,
          min_value: _vm.deveValidar ? 0.1 : false,
          max_value: _vm.deveValidar ? _vm.quantidadeTotalDisponivel : false,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Quantidade (Ton)","disabled":_vm.disabled || _vm.replicacao.cadencia_livre,"step":0.1,"min":0.1,"error-messages":errors,"readonly":_vm.suspendido,"hint":_vm.deveValidar
              ? ("Quantidade restante: " + _vm.quantidadeRestanteTotal)
              : null,"persistent-hint":_vm.deveValidar},model:{value:(_vm.replicacao.quantidade),callback:function ($$v) {_vm.$set(_vm.replicacao, "quantidade", $$v)},expression:"replicacao.quantidade"}})]}}],null,true)})],1),(_vm.to === 'transportadora')?_c('v-col',[_c('validation-provider',{attrs:{"name":"cadencia_livre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"label":"Cadência Livre?","disabled":_vm.disabled || _vm.suspendido,"error-messages":errors},on:{"click":_vm.cadencia_livre},model:{value:(_vm.replicacao.cadencia_livre),callback:function ($$v) {_vm.$set(_vm.replicacao, "cadencia_livre", $$v)},expression:"replicacao.cadencia_livre"}})]}}],null,true)})],1):_vm._e(),(!_vm.isEdit)?_c('v-col',{staticClass:"d-flex flex-column"},[_c('v-btn',{staticClass:"mx-2 mb-2",attrs:{"small":"","color":_vm.add ? 'primary' : 'error',"disabled":(_vm.deveValidar && _vm.quantidadeExcedida) ||
          invalid ||
          !_vm.podeAdicionar ||
          _vm.suspendido},on:{"click":_vm.handleClick}},[_vm._v(_vm._s(_vm.add ? 'Adicionar' : 'Excluir'))]),(_vm.replicacao.public_id)?_c('v-btn',{staticClass:"mx-2 mb-2",attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.handleSuspender(_vm.replicacao)}}},[_vm._v(_vm._s(_vm.suspendido ? 'Suspensão' : 'Suspender'))]):_vm._e(),(_vm.replicacao.public_id)?_c('v-btn',{staticClass:"mx-2 mb-2",attrs:{"small":"","color":"primary","disabled":_vm.suspendido},on:{"click":function($event){return _vm.handleAgendar(_vm.replicacao)}}},[_vm._v("Agendar")]):_vm._e()],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }