<template>
  <div>
    <div v-if="activator">
      <v-btn v-if="canDelete" color="error" dark class="mb-2" v-on="open">{{
        item.suspendido == false ? 'Suspender' : 'Suspensão'
      }}</v-btn>
    </div>
    <v-dialog v-model="dialog" v-if="item.public_id" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-0">
            <v-col class="pt-0 d-flex justify-space-between" cols="12">
              <div class="d-flex flex-column my-0">
                <span class="text-caption">Pedido: {{ item.public_id }}</span>
                <span class="text-caption" v-if="editedItem.public_id"
                  >Suspensao: {{ editedItem.public_id }}</span
                >
              </div>
            </v-col>
          </v-row>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.descricao"
                  label="Descrição"
                  required
                  :readonly="!proprietario"
                >
                  <template v-slot:label>
                    <div>Descrição <small>(Motivo da suspensão)</small></div>
                  </template>
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-0 text-center">
                <v-alert dense outlined type="error" v-if="!proprietario"
                  >Você não é o proprietário dessa suspensão
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            v-if="editedItem.public_id != ''"
            :disabled="!proprietario"
            text
            @click="del(editedItem)"
            >Liberar Suspensão</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="editedItem.descricao == '' || !proprietario"
            @click="save"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BasicPermissions } from '@/utils/permissions'

export default {
  name: 'Suspensao',
  props: {
    item: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    permission: {
      type: String,
      required: true,
    },
    activator: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      editedItem: {},
      defaultItem: {
        public_id: '',
        public_id_objeto: '',
        descricao: '',
        proprietario: null,
        model: this.model,
        suspendido: null,
        created: '',
        created_by: null,
      },
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    suspendido() {
      return this.item?.suspendido
    },
    allowedToAdd() {
      return this.empresaAtual.gate
    },
    formTitle() {
      return this.item?.suspendido ? 'Editar Suspensão' : 'Cadastrar Suspensão'
    },

    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    proprietario() {
      return (
        this.editedItem.public_id == '' ||
        this.editedItem.proprietario == this.empresaAtual.public_id
      )
    },
  },
  methods: {
    ...mapActions('patio', [
      'addSuspensao',
      'editSuspensao',
      'removerSuspensao',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    close() {
      this.dialog = false
    },
    open() {
      const suspensoes = this.item.suspensoes ? this.item.suspensoes : []
      this.editedItem = suspensoes.length > 0 ? suspensoes[0] : this.defaultItem
      this.dialog = true
    },
    async save() {
      try {
        this.editedItem.public_id_objeto = this.item.public_id
        let suspensao
        if (this.editedItem.public_id != '') {
          suspensao = await this.editSuspensao(this.editedItem)
        } else {
          suspensao = await this.addSuspensao(this.editedItem)
        }
        if (typeof this.item.suspensoes == typeof []) {
          this.item.suspensoes.push(suspensao)
        } else {
          this.item.suspensoes = [suspensao]
        }
        this.item.suspendido = true
        this.successMessage('Suspendido!')
        this.$emit('save', suspensao)
        this.close()
      } catch (error) {
        console.error(error)
        if (
          error.response &&
          (error.response.status == 403 || error.response.status == 404)
        ) {
          this.errorMessage('Empresa atual não pode suspender.')
          return
        }
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },

    async del(suspensao) {
      if (suspensao.public_id != null) {
        try {
          await this.removerSuspensao(suspensao)
          this.item.suspensoes = this.item.suspensoes.filter(
            (r) => r.public_id !== suspensao.public_id
          )
          if (this.item.suspensoes.length > 1) {
            this.item.suspendido = true
          } else {
            this.item.suspendido = false
          }
          this.$emit('delete', suspensao)
          this.close()
        } catch (error) {
          console.error(error)
          if (
            error.response &&
            (error.response.status == 403 || error.response.status == 404)
          ) {
            this.errorMessage(
              'Empresa atual não possui pode editar a suspensão.'
            )
            return
          }
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
  },
}
</script>
