<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Pedidos">
          <cadastro-pedido
            v-if="showForm"
            :pedidoEdit="pedidoEdit"
            :pedidoASerDistribuido="pedidoASerDistribuido"
            @close="close"
          />
          <editar-pedido
            v-else-if="showFormEdit"
            :pedidoASerEditado="pedidoASerEditado"
            @close="close"
          />
          <relatorio-pedido
            v-else-if="showFormRelatorio"
            :pedido="pedido"
            @close="close"
          />
          <listar-pedido
            v-else
            @showForm="showForm = true"
            @editItem="editItem"
            @distribuirParaTransportadora="handleDistribuirParaTransportadora"
            @editar="handleEditar"
            @showRelatorio="showRelatorio"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ListarPedido from '@/components/patio/Pedidos/ListarPedido'
import CadastroPedido from '@/components/patio/Pedidos/CadastroPedido'
import EditarPedido from '@/components/patio/Pedidos/EditarPedido'
import RelatorioPedido from '@/components/patio/Pedidos/RelatorioPedido'

export default {
  name: 'Pedido',

  data() {
    return {
      showForm: false,
      showFormEdit: false,
      showFormRelatorio: false,
      pedido: {},
      pedidoEdit: {},

      pedidoASerEditado: {},

      pedidoASerDistribuido: {},

      page: {
        title: 'Gerenciamento de pedidos',
      },
      breadcrumbs: [
        {
          text: 'Pátios',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Pedidos',
          disabled: true,
        },
      ],
    }
  },
  components: {
    ListarPedido,
    CadastroPedido,
    EditarPedido,
    RelatorioPedido,
  },
  methods: {
    showRelatorio(item) {
      this.showFormRelatorio = true
      this.pedido = { ...item }
    },
    editItem(item) {
      this.showForm = true
      this.pedidoEdit = { ...item }
    },

    close() {
      this.showForm = false
      this.showFormEdit = false
      this.showFormRelatorio = false
      this.pedidoEdit = {}
      this.pedidoASerDistribuido = {}
      this.pedidoASerEditado = {}
      this.pedido = {}
    },

    handleDistribuirParaTransportadora(item) {
      this.pedidoASerDistribuido = item
      this.showForm = true
    },
    handleEditar(item) {
      this.pedidoASerEditado = item
      this.showFormEdit = true
    },
  },
}
</script>

<style></style>
