<template>
  <validation-observer v-slot="{ invalid }" ref="observer">
    <v-col cols="12" sm="12" md="12" class="d-flex align-center">
      <v-col cols="12" sm="12" md="1">
        <validation-provider
          v-slot="{ errors }"
          :name="`nº pedido ${to}`"
          :rules="{ required }"
        >
          <v-text-field
            v-model="replicacao.numero_pedido"
            label="Nº Pedido"
            title="Número do pedido do"
            required
            :error-messages="errors"
            :disabled="disabled || temPedidoASerDistribuido"
            :readonly="disabled || temPedidoASerDistribuido || suspendido"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <validation-provider
          v-slot="{ errors }"
          :name="to"
          :rules="{ required }"
        >
          <v-autocomplete
            :label="toCapitalized"
            required
            :items="toItems"
            v-model="replicacao.to"
            :item-text="getLabelEmpresa"
            item-value="public_id"
            :disabled="disabled"
            :error-messages="errors"
            :readonly="suspendido"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="12" :md="isEdit ? 4 : 2">
        <validation-provider
          v-slot="{ errors }"
          name="terminal"
          :rules="{ required }"
        >
          <v-autocomplete
            label="Terminal"
            required
            :items="terminais"
            v-model="replicacao.terminal"
            :item-text="getLabelEmpresa"
            item-value="public_id"
            :disabled="disabled"
            :error-messages="errors"
            :readonly="suspendido"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <validation-provider
          v-slot="{ errors }"
          name="quantidade (Ton)"
          :rules="{
            required,
            min_value: deveValidar ? 0.1 : false,
            max_value: deveValidar ? quantidadeTotalDisponivel : false,
          }"
        >
          <v-text-field
            type="number"
            label="Quantidade (Ton)"
            v-model="replicacao.quantidade"
            :disabled="disabled || replicacao.cadencia_livre"
            :step="0.1"
            :min="0.1"
            :error-messages="errors"
            :readonly="suspendido"
            :hint="
              deveValidar
                ? `Quantidade restante: ${quantidadeRestanteTotal}`
                : null
            "
            :persistent-hint="deveValidar"
          />
        </validation-provider>
      </v-col>

      <v-col v-if="to === 'transportadora'">
        <validation-provider v-slot="{ errors }" name="cadencia_livre">
          <v-checkbox
            label="Cadência Livre?"
            v-model="replicacao.cadencia_livre"
            :disabled="disabled || suspendido"
            :error-messages="errors"
            @click="cadencia_livre"
          />
        </validation-provider>
      </v-col>
      <v-col class="d-flex flex-column" v-if="!isEdit">
        <v-btn
          class="mx-2 mb-2"
          small
          :color="add ? 'primary' : 'error'"
          :disabled="
            (deveValidar && quantidadeExcedida) ||
            invalid ||
            !podeAdicionar ||
            suspendido
          "
          @click="handleClick"
          >{{ add ? 'Adicionar' : 'Excluir' }}</v-btn
        >
        <v-btn
          class="mx-2 mb-2"
          small
          color="warning"
          v-if="replicacao.public_id"
          @click="handleSuspender(replicacao)"
          >{{ suspendido ? 'Suspensão' : 'Suspender' }}</v-btn
        >
        <v-btn
          class="mx-2 mb-2"
          small
          color="primary"
          v-if="replicacao.public_id"
          :disabled="suspendido"
          @click="handleAgendar(replicacao)"
          >Agendar</v-btn
        >
      </v-col>
    </v-col>
  </validation-observer>
</template>

<script>
export default {
  name: 'Replicacao',

  props: {
    temPedidoASerDistribuido: Boolean,
    add: Boolean,
    required: Boolean,
    isEdit: Boolean,
    navioFoiSelecionado: Boolean,
    to: {
      type: String,
      default: 'cliente',
    },
    totalDisponivel: {
      type: Number,
      default: 0,
    },
    quantidadeTotalPedidoPorClienteTerminal: {
      type: Array,
    },
    quantidadeTotalDistribuicaoPorClienteTerminal: {
      type: Array,
    },
    replicacao: {
      type: Object,
      required: true,
    },
    toItems: {
      type: Array,
      required: true,
    },
    terminais: {
      type: Array,
      required: true,
    },
    suspendido: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      },
    },
  },

  data() {
    return {
      quantidadeTotalDisponivel: this.totalDisponivel,
    }
  },

  computed: {
    quantidadeDigitada() {
      return Number(this.replicacao.quantidade) || 0
    },

    quantidadeExcedida() {
      return (
        this.deveValidar &&
        (this.quantidadeDigitada > this.quantidadeTotalDisponivel ||
          this.quantidadeTotalDisponivel === 0)
      )
    },

    quantidadeRestanteTotal() {
      return this.quantidadeTotalDisponivel - this.quantidadeDigitada
    },

    deveValidar() {
      return (
        !this.replicacao.cadencia_livre &&
        (this.deveValidarCliente || this.deveValidarTransportadora)
      )
    },

    deveValidarCliente() {
      return (
        (this.add || this.isEdit) &&
        this.navioFoiSelecionado &&
        this.to === 'cliente'
      )
    },

    deveValidarTransportadora() {
      return (this.add || this.isEdit) && this.to === 'transportadora'
    },

    disabled() {
      return !this.add && !this.isEdit && this.to != 'transportadora'
    },

    podeAdicionar() {
      const {
        numero_pedido,
        to,
        terminal,
        quantidade,
        cadencia_livre,
      } = this.replicacao

      return numero_pedido && to && terminal && (cadencia_livre || quantidade)
    },

    toCapitalized() {
      return this.to.charAt(0).toUpperCase() + this.to.substring(1)
    },
  },

  watch: {
    replicacao: {
      handler: function () {
        if (this.deveValidarCliente) {
          const { to, terminal } = this.replicacao
          if (to && terminal) {
            this.quantidadeTotalDisponivel = this.getQuantidadeTotalDisponivelCliente()
          }
        }
      },
      deep: true,
    },
    totalDisponivel(valor) {
      this.quantidadeTotalDisponivel = valor
    },
  },

  methods: {
    handleSuspender(item) {
      this.$emit('handleSuspender', item)
    },
    handleAgendar(item) {
      this.$emit('handleAgendar', item)
    },
    cadencia_livre() {
      this.replicacao.quantidade = 0
    },
    getLabelEmpresa(item) {
      return `${item.business_name} ${item.cnpj}`
    },

    getClienteTerminal(obj) {
      const { to, terminal } = this.replicacao

      return obj.cliente__public_id === to && obj.terminal === terminal
    },

    getQuantidadeTotalDisponivelCliente() {
      const foiFeitoAlgumPedido = this.quantidadeTotalPedidoPorClienteTerminal
        .length
      const foiFeitoAlgumaDistribuicao = this
        .quantidadeTotalDistribuicaoPorClienteTerminal.length

      if (foiFeitoAlgumaDistribuicao) {
        const pedidoClienteTerminal = this.quantidadeTotalPedidoPorClienteTerminal.find(
          this.getClienteTerminal
        )
        const distribuicaoClienteTerminal = this.quantidadeTotalDistribuicaoPorClienteTerminal.find(
          this.getClienteTerminal
        )

        if (pedidoClienteTerminal && distribuicaoClienteTerminal) {
          return (
            distribuicaoClienteTerminal.quantidade__sum -
            pedidoClienteTerminal.quantidade__sum
          )
        } else if (
          (!foiFeitoAlgumPedido || !pedidoClienteTerminal) &&
          distribuicaoClienteTerminal
        ) {
          return distribuicaoClienteTerminal.quantidade__sum
        }
      }

      return 0
    },

    handleClick() {
      if (this.add && !this.quantidadeExcedida) {
        if (this.quantidadeTotalPedidoPorClienteTerminal) {
          const obj = this.quantidadeTotalPedidoPorClienteTerminal.find(
            this.getClienteTerminal
          )

          if (obj) obj.quantidade__sum += this.quantidadeDigitada
          else {
            this.quantidadeTotalPedidoPorClienteTerminal.push({
              cliente__public_id: this.replicacao.to,
              terminal: this.replicacao.terminal,
              quantidade__sum: this.quantidadeDigitada,
            })
          }
          this.quantidadeTotalDisponivel = 0
        }

        this.$emit('addReplicacao')
      } else if (!this.add) {
        if (this.quantidadeTotalPedidoPorClienteTerminal) {
          const obj = this.quantidadeTotalPedidoPorClienteTerminal.find(
            this.getClienteTerminal
          )

          if (obj) {
            obj.quantidade__sum -= this.quantidadeDigitada
          }
        }

        this.$emit('removeReplicacao')
      }
    },
  },
}
</script>
