<template>
  <v-col :cols="cols">
    <v-card v-if="!isEdit">
      <v-card-subtitle>Replicar Pedido</v-card-subtitle>
      <v-card-text>
        <replicacao
          :toItems="clientes"
          :terminais="terminais"
          :replicacao="replicacao"
          :required="!replicacoes.length"
          :navioFoiSelecionado="navioFoiSelecionado"
          :quantidadeTotalPedidoPorClienteTerminal="
            quantidadeTotalPedidoPorClienteTerminal
          "
          :quantidadeTotalDistribuicaoPorClienteTerminal="
            quantidadeTotalDistribuicaoPorClienteTerminal
          "
          @addReplicacao="handleAddReplicacao"
          add
        />
      </v-card-text>
    </v-card>
    <v-card class="mt-8" v-if="replicacoes.length">
      <v-card-subtitle>Replicações Cadastradas</v-card-subtitle>
      <v-card-text>
        <div v-for="(repl, index) of replicacoes" :key="index">
          <replicacao
            :toItems="clientes"
            :terminais="terminais"
            :replicacao="repl"
            :navioFoiSelecionado="navioFoiSelecionado"
            :isEdit="isEdit"
            :quantidadeTotalPedidoPorClienteTerminal="
              quantidadeTotalPedidoPorClienteTerminal
            "
            :quantidadeTotalDistribuicaoPorClienteTerminal="
              quantidadeTotalDistribuicaoPorClienteTerminal
            "
            @removeReplicacao="() => handleRemoveReplicacao(index)"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import PatioAPI from '@/services/patio'
import ClienteAPI from '@/services/tenants/clientes'
import ContratoAPI from '@/services/tenants/contratos'

import Perfil from '@/utils/perfil'

import Replicacao from './Replicacao.vue'

export default {
  name: 'ReplicacaoCliente',

  components: {
    Replicacao,
  },

  props: {
    pedido: {
      type: Object,
      required: true,
    },
    navioFoiSelecionado: {
      type: Boolean,
    },
    cols: {
      type: Number,
      default: 12,
    },
    isEdit: {
      type: Boolean,
    },
  },

  data() {
    return {
      fornecedorSelecionado: null,
      replicacao: {},

      replicacoes: [],
      clientes: [],
      terminais: [],
      quantidadeTotalPedidoPorClienteTerminal: [],
      quantidadeTotalDistribuicaoPorClienteTerminal: [],
    }
  },

  computed: {
    fornecedorFoiSelecionado() {
      return Object.keys(this.pedido.fornecedor).length > 0
    },
  },

  methods: {
    async getQuantidadesTotais() {
      const { fornecedor, navio, produto, subProduto } = this.pedido
      const requisitosPreenchidos = fornecedor && navio && produto && subProduto

      if (requisitosPreenchidos) {
        const [
          dataPedido,
          dataDistribuicao,
        ] = await PatioAPI.getQuantidadeRestante({
          fornecedor: fornecedor.public_id,
          produto: produto.id,
          sub_produto: subProduto,
          navio: navio,
        })

        if (this.isEdit) {
          const { cliente, terminal, quantidade } = this.pedidoEdit

          const total = dataPedido.find(
            (t) =>
              t.cliente__public_id === cliente.public_id &&
              t.terminal === terminal.id
          )

          total.quantidade__sum -= quantidade
        }

        Object.assign(this.quantidadeTotalPedidoPorClienteTerminal, dataPedido)
        Object.assign(
          this.quantidadeTotalDistribuicaoPorClienteTerminal,
          dataDistribuicao
        )
      }
    },

    handleAddReplicacao() {
      this.replicacoes.push({ ...this.replicacao, cliente: this.replicacao.to })

      this.replicacao = {}
    },

    handleRemoveReplicacao(index) {
      this.replicacoes.splice(index, 1)
    },
  },

  watch: {
    pedido: {
      async handler(pedido) {
        const { fornecedor } = pedido
        if (this.fornecedorFoiSelecionado) {
          if (
            this.fornecedorSelecionado == null ||
            this.fornecedorSelecionado.public_id != fornecedor.public_id
          ) {
            this.fornecedorSelecionado = { ...fornecedor }
            this.clientes = await ClienteAPI.getClientes(
              fornecedor.fornecedor_id
            )
            this.terminais = await ContratoAPI.getContratos(
              fornecedor.fornecedor_id,
              { perfil: Perfil.Terminal }
            )
          }
        }

        await this.getQuantidadesTotais()
      },
      deep: true,
    },
  },
}
</script>
