<template>
  <v-col :cols="cols">
    <v-card>
      <v-card-subtitle>Replicar Pedido</v-card-subtitle>
      <v-card-text>
        <replicacao
          :toItems="theTransportadoras"
          :terminais="terminais"
          :replicacao="replicacao"
          :required="!replicacoes.length"
          :totalDisponivel="totalDisponivel"
          :temPedidoASerDistribuido="temPedidoASerDistribuido"
          to="transportadora"
          @addReplicacao="handleAddReplicacao"
          add
        />
      </v-card-text>
    </v-card>
    <v-card class="mt-8" v-if="replicacoes.length">
      <v-card-subtitle>Replicações Cadastradas</v-card-subtitle>
      <v-card-text>
        <div v-for="(repl, index) of replicacoes" :key="index">
          <replicacao
            ref="items"
            :toItems="theTransportadoras"
            :terminais="terminais"
            :replicacao="repl"
            :suspendido="repl.suspendido"
            to="transportadora"
            @handleAgendar="handleAgendar(index)"
            @removeReplicacao="() => handleRemoveReplicacao(index)"
            @handleSuspender="handleSuspender"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex'

import TransportadoraAPI from '@/services/tenants/transportadoras'
import ContratoAPI from '@/services/tenants/contratos'
import PatioAPI from '@/services/patio'
import Perfil from '@/utils/perfil'
import Replicacao from './Replicacao.vue'
import TipoAgendamento from '@/utils/agendamento'

export default {
  name: 'ReplicacaoTransportadora',

  components: {
    Replicacao,
  },

  props: {
    temPedidoASerDistribuido: Boolean,
    cols: {
      type: Number,
      default: 12,
    },
    pedido: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      replicacao: {
        numero_pedido: this.pedido.numero_pedido,
      },
      totalDisponivel: this.pedido.quantidade_restante,

      replicacoes: [],
      transportadoras: [],
      terminais: [],
    }
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
    theTransportadoras() {
      return []
        .concat(
          this.transportadoras,
          this.pedido.pedidos.map((item) => item.transportadora)
        )
        .filter((item) => item)
    },
  },

  methods: {
    handleSuspender(item) {
      this.$emit('handleSuspender', item)
    },
    handleSave() {
      this.$refs.items.forEach((element) => {
        const replicacao = element.replicacao
        if (replicacao.public_id) {
          const index = this.replicacoes.findIndex(
            (item) => item.public_id == replicacao.public_id
          )
          if (index >= 0) {
            Object.assign(this.replicacoes[index], replicacao)
            this.replicacoes[index].transportadora = replicacao.to
          }
        }
      })
    },
    handleAgendar(index) {
      const public_id = this.replicacoes[index]?.public_id
      if (!public_id) {
        return
      }
      const pedido = this.pedido.pedidos.find(
        (item) => item.public_id == public_id
      )
      if (!pedido) {
        return
      }
      const dados = {
        public_id: pedido.public_id,
        data_inicio: pedido.data_inicio,
        data_fim: pedido.data_fim,
        numero_pedido_importacao: pedido.numero_pedido_importacao,
        numero_pedido: pedido.numero_pedido,
        gate: pedido.gate,
        proprietario: pedido.proprietario,
        fornecedor: pedido.fornecedor,
        cliente: pedido.cliente,
        transportadora: pedido.transportadora,
        tipoFrete: pedido.tipoFrete,
        terminal: pedido.terminal,
        produto: pedido.produto,
        subProduto: pedido.subProduto,
        embalagem: pedido.embalagem,
        navio: pedido.navio,
        distribuicao: pedido.distribuicao,
        pedido_pai: {
          public_id: pedido.public_id,
          quantidade_restante: pedido.quantidade_restante,
        },
        quantidade: pedido.quantidade,
        quantidade_restante: pedido.quantidade_restante,
        cadencia_livre: pedido.cadencia_livre,
      }
      this.$router.push({
        // name: 'Agendamentos',
        name: 'NovoAgendamento',
        params: {
          pedidoSelecionado: dados,
          tipoAgendamento: TipoAgendamento.DoPedido,
        },
      })
    },

    handleAddReplicacao() {
      this.replicacoes.push({
        ...this.replicacao,
        transportadora: this.replicacao.to,
      })

      if (!this.pedido.cadencia_livre) {
        this.totalDisponivel -= Number(this.replicacao.quantidade)
      }

      this.replicacao = {
        numero_pedido: this.pedido.numero_pedido,
      }
    },

    handleRemoveReplicacao(index) {
      if (!this.pedido.cadencia_livre) {
        this.totalDisponivel += Number(this.replicacoes[index].quantidade)
      }
      PatioAPI.inativarPedido({
          pedido: this.replicacoes[index].public_id
      })
      
      this.replicacoes.splice(index, 1)
    },
  },

  async created() {
    const { fornecedor } = this.pedido

    this.replicacoes = this.pedido.replicacoes

    this.transportadoras = await TransportadoraAPI.getTransportadoras(
      this.empresaAtual
    )
    this.terminais = await ContratoAPI.getContratos(fornecedor.fornecedor_id, {
      perfil: Perfil.Terminal,
    })
  },
}
</script>
