var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{staticClass:"mt-4"},[_c('validation-observer',{ref:"observer"},[_c('v-card-text',{staticClass:"py-6"},[(Object.keys(_vm.pedido).length)?_c('v-container',[_c('v-row',[(!_vm.empresaAtual.gate)?_c('v-col',{staticClass:"d-md-flex mb-0 pb-0",attrs:{"cols":"12","sm":"12","md":"12","xl":"10"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"gate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Gate/Unidade Destino","items":[_vm.pedido.gate],"item-text":_vm.getLabelEmpresa,"item-value":"public_id","return-object":"","error-messages":errors},model:{value:(_vm.pedido.gate),callback:function ($$v) {_vm.$set(_vm.pedido, "gate", $$v)},expression:"pedido.gate"}})]}}],null,false,3175219207)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"U.F","disabled":"","error-messages":errors},model:{value:(_vm.pedido.gate.state),callback:function ($$v) {_vm.$set(_vm.pedido.gate, "state", $$v)},expression:"pedido.gate.state"}})]}}],null,false,1197572609)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"município"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Município","disabled":"","error-messages":errors},model:{value:(_vm.pedido.gate.city),callback:function ($$v) {_vm.$set(_vm.pedido.gate, "city", $$v)},expression:"pedido.gate.city"}})]}}],null,false,2738400651)})],1)],1):_vm._e(),_c('v-col',{staticClass:"d-md-flex justify-center mb-0 pb-0",attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"10"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"data"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Data","error-messages":errors,"type":"date"},model:{value:(_vm.pedido.data_inicio),callback:function ($$v) {_vm.$set(_vm.pedido, "data_inicio", $$v)},expression:"pedido.data_inicio"}})]}}],null,false,1302777626)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"tipo de frete"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Tipo de frete","items":[_vm.pedido.tipoFrete],"item-text":"descricao","item-value":"id","error-messages":errors},model:{value:(_vm.pedido.tipoFrete),callback:function ($$v) {_vm.$set(_vm.pedido, "tipoFrete", $$v)},expression:"pedido.tipoFrete"}})]}}],null,false,976624788)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"fornecedor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.empresaAtual.gate)?_c('v-autocomplete',{attrs:{"label":"Fornecedor","items":[_vm.pedido.fornecedor],"item-text":_vm.getLabelEmpresa,"item-value":"public_id","return-object":"","error-messages":errors},model:{value:(_vm.pedido.fornecedor),callback:function ($$v) {_vm.$set(_vm.pedido, "fornecedor", $$v)},expression:"pedido.fornecedor"}}):_vm._e()]}}],null,false,3048990302)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"estado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"U.F","disabled":"","error-messages":errors},model:{value:(_vm.pedido.fornecedor.state),callback:function ($$v) {_vm.$set(_vm.pedido.fornecedor, "state", $$v)},expression:"pedido.fornecedor.state"}})]}}],null,false,3040230745)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"município"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Município","disabled":"","error-messages":errors},model:{value:(_vm.pedido.fornecedor.city),callback:function ($$v) {_vm.$set(_vm.pedido.fornecedor, "city", $$v)},expression:"pedido.fornecedor.city"}})]}}],null,false,1534185427)})],1)],1),_c('v-col',{staticClass:"d-md-flex mb-0 pb-0",attrs:{"cols":"12","sm":"12","md":"12","xl":"10"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"navio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Navio","item-text":"descricao","item-value":"id","return-object":"","error-messages":errors},model:{value:(_vm.pedido.navio),callback:function ($$v) {_vm.$set(_vm.pedido, "navio", $$v)},expression:"pedido.navio"}})]}}],null,false,3817847512)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"nº pedido importação"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nº Pedido Importação","title":"Número do pedido da importação","error-messages":errors},model:{value:(_vm.pedido.numero_pedido_importacao),callback:function ($$v) {_vm.$set(_vm.pedido, "numero_pedido_importacao", $$v)},expression:"pedido.numero_pedido_importacao"}})]}}],null,false,3025175253)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"produto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Produto","items":[_vm.pedido.produto],"item-text":"descricao","item-value":"id","return-object":"","error-messages":errors},model:{value:(_vm.pedido.produto),callback:function ($$v) {_vm.$set(_vm.pedido, "produto", $$v)},expression:"pedido.produto"}})]}}],null,false,1760489236)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"sub produto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Sub Produto","items":[_vm.pedido.subProduto],"item-text":"descricao","item-value":"id","error-messages":errors},model:{value:(_vm.pedido.subProduto),callback:function ($$v) {_vm.$set(_vm.pedido, "subProduto", $$v)},expression:"pedido.subProduto"}})]}}],null,false,3567312244)})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('validation-provider',{attrs:{"name":"embalagem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Embalagem","items":[_vm.pedido.embalagem],"item-text":"descricao","item-value":"id","error-messages":errors},model:{value:(_vm.pedido.embalagem),callback:function ($$v) {_vm.$set(_vm.pedido, "embalagem", $$v)},expression:"pedido.embalagem"}})]}}],null,false,2113649694)})],1)],1),_c('v-col',{staticClass:"d-md-flex mb-0 pb-0",attrs:{"cols":"12","sm":"12","md":"12","xl":"10"}},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"4"}},[_c('validation-provider',{attrs:{"name":"quantidade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Quantidade Total (Ton)","min":_vm.pedido.quantidade_usada,"error-messages":errors},model:{value:(_vm.pedido.quantidade),callback:function ($$v) {_vm.$set(_vm.pedido, "quantidade", $$v)},expression:"pedido.quantidade"}})]}}],null,false,2133696961)})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"type":"number","label":"Quantidade Usada (Ton)","disabled":true},model:{value:(_vm.pedido.quantidade_usada),callback:function ($$v) {_vm.$set(_vm.pedido, "quantidade_usada", $$v)},expression:"pedido.quantidade_usada"}})],1)],1)],1)],1):_vm._e()],1),_c('v-card-actions')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }