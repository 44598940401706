<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="mt-4">
      <validation-observer ref="observer">
        <v-card-text class="py-6">
          <v-container v-if="Object.keys(pedido).length">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                xl="10"
                class="d-md-flex mb-0 pb-0"
                v-if="!empresaAtual.gate"
              >
                <v-col cols="12" sm="12" md="4">
                  <validation-provider v-slot="{ errors }" name="gate">
                    <v-autocomplete
                      label="Gate/Unidade Destino"
                      :items="[pedido.gate]"
                      v-model="pedido.gate"
                      :item-text="getLabelEmpresa"
                      item-value="public_id"
                      return-object
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <validation-provider v-slot="{ errors }" name="estado">
                    <v-text-field
                      label="U.F"
                      v-model="pedido.gate.state"
                      disabled
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <validation-provider v-slot="{ errors }" name="município">
                    <v-text-field
                      label="Município"
                      v-model="pedido.gate.city"
                      disabled
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                lg="12"
                xl="10"
                class="d-md-flex justify-center mb-0 pb-0"
              >
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="data">
                    <v-text-field
                      v-model="pedido.data_inicio"
                      label="Data"
                      :error-messages="errors"
                      type="date"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="tipo de frete">
                    <v-autocomplete
                      label="Tipo de frete"
                      :items="[pedido.tipoFrete]"
                      v-model="pedido.tipoFrete"
                      item-text="descricao"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <validation-provider v-slot="{ errors }" name="fornecedor">
                    <v-autocomplete
                      v-if="empresaAtual.gate"
                      label="Fornecedor"
                      :items="[pedido.fornecedor]"
                      v-model="pedido.fornecedor"
                      :item-text="getLabelEmpresa"
                      item-value="public_id"
                      return-object
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="estado">
                    <v-text-field
                      label="U.F"
                      v-model="pedido.fornecedor.state"
                      disabled
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="município">
                    <v-text-field
                      label="Município"
                      v-model="pedido.fornecedor.city"
                      disabled
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
                xl="10"
                class="d-md-flex mb-0 pb-0"
              >
                <v-col cols="12" sm="12" md="3">
                  <validation-provider v-slot="{ errors }" name="navio">
                    <v-autocomplete
                      label="Navio"
                      v-model="pedido.navio"
                      item-text="descricao"
                      item-value="id"
                      return-object
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="nº pedido importação"
                  >
                    <v-text-field
                      v-model="pedido.numero_pedido_importacao"
                      label="Nº Pedido Importação"
                      title="Número do pedido da importação"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="produto">
                    <v-autocomplete
                      label="Produto"
                      :items="[pedido.produto]"
                      v-model="pedido.produto"
                      item-text="descricao"
                      item-value="id"
                      return-object
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="sub produto">
                    <v-autocomplete
                      label="Sub Produto"
                      :items="[pedido.subProduto]"
                      v-model="pedido.subProduto"
                      item-text="descricao"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" sm="12" md="2">
                  <validation-provider v-slot="{ errors }" name="embalagem">
                    <v-autocomplete
                      label="Embalagem"
                      :items="[pedido.embalagem]"
                      v-model="pedido.embalagem"
                      item-text="descricao"
                      item-value="id"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                xl="10"
                class="d-md-flex mb-0 pb-0"
              >
                <v-col cols="6" sm="6" md="4">
                  <validation-provider v-slot="{ errors }" name="quantidade">
                    <v-text-field
                      type="number"
                      label="Quantidade Total (Ton)"
                      v-model="pedido.quantidade"
                      :min="pedido.quantidade_usada"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="6" sm="6" md="4">
                  <v-text-field
                    type="number"
                    label="Quantidade Usada (Ton)"
                    v-model="pedido.quantidade_usada"
                    :disabled="true"
                  />
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'RelatorioPedido',

  props: {
    pedido: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      overlay: false,
    }
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
  },

  async created() {},

  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),

    getLabelEmpresa(item) {
      return `${item.business_name} ${item.cnpj}`
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style>
.table__distribuicoes tr {
  cursor: pointer;
}
</style>
