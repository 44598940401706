import axios from 'axios'

class ExtracaoApi {
    async getAgendamentoExportCsv(params) {
        try {
            const { data } = await axios.get(
                `/api/v1/tenants/agendamentos/export_csv_agendamento/`, { params }
            )
            return Promise.resolve(data)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    async getExportCsvPedidos(params) {
        try {
            const { data } = await axios.get(
                `/api/v1/patio/pedidos/export_csv_agendamento/`, { params }
            )
            return Promise.resolve(data)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    async getPedidoDetalhe(params) {
        try {
          const { data } = await axios.get(
            `api/v1/patio/pedidos/${params.public_id}/detalhes/`
          )
          return Promise.resolve(data)
        } catch (error) {
          return Promise.reject(error)
        }
    }

    async getPedidoDetalhePorTransportadora(params) {
        try {
          const { data } = await axios.get(
            `api/v1/patio/pedidos/${params.public_id}/total_transportadora/`
          )
          return Promise.resolve(data)
        } catch (error) {
          return Promise.reject(error)
        }
    }
}

export default new ExtracaoApi()